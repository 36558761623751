import React from "react";
import styled, { css } from "styled-components";

import InputErrorMessage from "./ErrorMessage";
import Label from "./Label";
import InputWrapper from "./InputWrapper";

const Textarea = styled.textarea`
  background-color: ${props => props.theme.colors.grey05};
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid ${props => props.theme.colors.grey03};
  caret-color: ${props => props.theme.colors.black01};
  color: ${props => props.theme.colors.primary};
  display: block;
  font-size: ${props => props.theme.fontSizes.mobile.s};
  line-height: ${props => props.theme.lineHeights.mobile.s};
  padding: 12px;
  transition: all 0.2s ease;
  width: 100%;
  height: 150px;

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${props => props.theme.colors.grey03};
  }

  :disabled {
    color: ${props => props.theme.colors.grey03};
    border: 1px solid ${props => props.theme.colors.grey03};
    background-color: ${props => props.theme.colors.grey04};
  }

  :focus {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.black01};
    border-bottom: 1px solid ${props => props.theme.colors.black01};
    box-shadow: none;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${props => props.theme.colors.grey03};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${props => props.theme.colors.grey03};
  }

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    font-size: ${props => props.theme.fontSizes.desktop.s}px;
    line-height: ${props => props.theme.lineHeights.mobile.s}px;
  }

  ${props =>
    props.error &&
    css`
      border-bottom: 1px solid ${props => props.theme.colors.red};
      color: ${props => props.theme.colors.red};
    `}
`;

const InputTextarea = ({
  autoComplete = "off",
  disabled,
  id,
  input,
  label,
  meta,
  onChange,
  placeholder,
}) => {
  const hasError = (meta.error || meta.submitError) && meta.touched;

  return (
    <InputWrapper>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Textarea
        rows={6}
        autoComplete={autoComplete}
        disabled={disabled}
        error={hasError}
        id={id}
        onChange={onChange}
        placeholder={placeholder}
        {...input}
      />
      {hasError && (
        <InputErrorMessage>{meta.error || meta.submitError}</InputErrorMessage>
      )}
    </InputWrapper>
  );
};

export default InputTextarea;
