import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Heading from "../typography/Heading";

const AlertWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 80px;
  left: 0;
  padding: 20px;
  color: ${props => props.theme.colors.primary};
  opacity: ${props => (props.hidden ? 0 : 1)};
  transition: 450ms ease-in-out opacity;
`;

const Alert = styled.span`
  background: ${props => props.theme.colors.white};
  padding: 20px;
  width: 100%;
  display: block;
  text-align: center;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.225);

  @media (min-width: ${props => props.theme.breakpoints.mobile}px) {
    max-width: 635px;
  }
`;

const FormAlert = () => {
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setHidden(true);
    }, 5000);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <AlertWrapper hidden={hidden}>
      <Alert>
        <Heading size="s" semiBold>
          Thank you for contacting us! We will be in touch soon.
        </Heading>
      </Alert>
    </AlertWrapper>
  );
};

export default FormAlert;
