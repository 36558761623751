const parseJSON = response => {
  if (response.status === 204 || response.status === 205) {
    return null;
  }

  return response.json();
};

const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  throw response;
};

const handleError = async response => {
  const error = await response.text().then(text => text);

  return Promise.reject(JSON.parse(error));
};

export const request = (url, options) => {
  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
    .catch(handleError);
};

export const parseAPIErrors = error => {
  const message = error.error.message;

  if (error && message && typeof message === "object") {
    return Object.keys(message).reduce((res, fieldName) => {
      const errorKey =
        fieldName.charAt(0).toLowerCase() + fieldName.substring(1);
      if (!res[errorKey]) {
        res[errorKey] = message[fieldName];
        if (typeof res[errorKey] === "object") {
          res[errorKey] = Object.keys(res[errKey]).reduce(
            (result, key) => `${result}, ${res[errKey][key]}`,
            ""
          );
        }
      }
      return res;
    }, {});
  }

  return {};
};
