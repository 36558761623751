import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Label = styled.label`
  display: block;
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes.desktop.xs}px;
  margin-bottom: 4px;
`;

const InputLabel = ({ className, htmlFor, children, as = "label" }) => (
  <Label as={as} className={className} htmlFor={htmlFor}>
    {children}
  </Label>
);

InputLabel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  as: PropTypes.string,
  htmlFor: PropTypes.string,
};

export default InputLabel;
