import React from "react";
import styled, { css } from "styled-components";

import ErrorMessage from "./ErrorMessage";
import Label from "./Label";
import InputWrapper from "./InputWrapper";

const Select = styled.select`
  box-sizing: border-box;
  background-clip: padding-box;
  background-color: ${(props) => props.theme.colors.grey05};
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M12 0L6 6L0 0H12Z' fill='%2306289F'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 14px);
  background-position-y: 20px;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey03};
  caret-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.primary};
  display: block;
  font-size: ${(props) => props.theme.fontSizes.mobile.s}px;
  width: 100%;
  line-height: 1.25;
  padding: 12px 40px 12px 12px;
  transition: all 0.2s ease;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${(props) => props.theme.colors.grey03};
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) => props.theme.colors.grey03};
  }

  :disabled {
    color: ${(props) => props.theme.colors.grey03};
    border: 1px solid ${(props) => props.theme.colors.grey03};
    background-color: ${(props) => props.theme.colors.grey04};
  }

  :focus {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.black01};
    border-bottom: 1px solid ${(props) => props.theme.colors.black01};
    box-shadow: none;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(props) => props.theme.colors.grey03};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    font-size: ${(props) => props.theme.fontSizes.desktop.s}px;
  }

  ${(props) =>
    props.error &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.colors.red};
      color: ${(props) => props.theme.colors.red};
    `}
`;

const InputSelect = ({
  autoComplete = "on",
  disabled,
  id,
  input,
  inputmode,
  label,
  options,
  meta,
  onChange,
  pattern,
  placeholder,
  type = "text",
}) => {
  const hasError = (meta.error || meta.submitError) && meta.touched;

  return (
    <InputWrapper>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Select
        autoComplete={autoComplete}
        disabled={disabled}
        error={hasError}
        id={id}
        inputmode={inputmode}
        onChange={onChange}
        pattern={pattern}
        placeholder={placeholder}
        type={type}
        {...input}
      >
        <option value="">Choose option...</option>
        {options.map((option, index) => {
          return (
            <option key={`option_${index}`} value={option}>
              {option}
            </option>
          );
        })}
      </Select>
      {hasError && (
        <ErrorMessage>{meta.error || meta.submitError}</ErrorMessage>
      )}
    </InputWrapper>
  );
};

export default InputSelect;
