import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import styled, { css } from "styled-components";
import { RichText } from "prismic-reactjs";
import { graphql, StaticQuery } from "gatsby";
import { Grid, Row, Col } from "react-styled-flexboxgrid";

import Layout from "../components/new/Layout";
import Heading from "../components/new/typography/Heading";
import Spacer from "../components/new/Spacer";
import ContactForm from "../components/new/forms/ContactForm";

import { extractSocialMetadata } from "../utils/content";

const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
    flex-direction: row;
  }
`;

const Intro = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px;

  @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
    width: 42%;
    padding: 0 50px;
  }
`;

const Form = styled.div`
  background-color: ${props => props.theme.colors.grey04};
  padding: 0 30px;

  @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
    width: 60%;
    padding: 0 70px;
  }

  input,
  textarea {
    &:focus {
      outline: none;
    }
  }
`;

const ContactPage = ({ data }) => {
  const content = data.prismic.contact_us;
  const meta = data.site.siteMetadata;

  if (!content) {
    return null;
  }

  const [hasSocialMetaData, socialMetadata] = extractSocialMetadata(
    content.body1
  );

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={content.meta_title}
        meta={[
          {
            name: `description`,
            content: content.meta_description,
          },
          {
            property: `og:title`,
            content: hasSocialMetaData
              ? socialMetadata.general_card.title
              : meta.title,
          },
          {
            property: `og:description`,
            content: hasSocialMetaData
              ? socialMetadata.general_card.description
              : content.meta_description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: hasSocialMetaData && socialMetadata.general_card.image,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: meta.author,
          },
          {
            name: `twitter:site`,
            content: hasSocialMetaData && socialMetadata.twitter_handle,
          },
          {
            name: `twitter:title`,
            content: hasSocialMetaData
              ? socialMetadata.twitter_card.title
              : meta.title,
          },
          {
            name: `twitter:description`,
            content: hasSocialMetaData
              ? socialMetadata.twitter_card.description
              : meta.description,
          },
          {
            name: `twitter:image`,
            content: hasSocialMetaData && socialMetadata.twitter_card.image,
          },
        ].concat(meta)}
      />
      <Layout navigationTransparent={false} padded>
        <Helmet>
          <script
            src="https://recaptcha.net/recaptcha/api.js"
            async
            defer
          ></script>
        </Helmet>

        <Content>
          <Intro>
            <Spacer
              padding={{
                xs: "60px 0 60px",
                m: "50px 0 100px",
                l: "70px 0 100px",
              }}
            >
              <Heading size="l" as="h2" margin="0 0 xxl">
                {content.heading}
              </Heading>
              {RichText.render(content.body_text)}
            </Spacer>
          </Intro>
          <Form>
            <Spacer>
              <ContactForm />
            </Spacer>
          </Form>
        </Content>
      </Layout>
    </>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
};

const query = graphql`
  {
    prismic {
      contact_us(lang: "en-gb", uid: "contact_us") {
        body_text
        heading
        meta_description
        meta_title
        body1 {
          ... on PRISMIC_Contact_usBody1General_card {
            type
            label
            primary {
              description
              image
              title
            }
          }
          ... on PRISMIC_Contact_usBody1Twitter_card {
            type
            label
            primary {
              card_type
              description
              image
              title
              twitter_handle
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

const ContactData = () => (
  <StaticQuery
    query={`${query}`}
    render={data => <ContactPage data={data} />}
  />
);

export default ContactData;
