import React from "react";

export const useDataLayer = () => {
  const browserWindow = typeof window !== "undefined" ? window : undefined;
  const dataLayer = (browserWindow && browserWindow.dataLayer) || [];

  return dataLayer;
};

export default useDataLayer;
