import React from "react";
import styled from "styled-components";

const Errors = styled.ul`
  list-style: none outside;
  margin-block-end: 0;
  margin-block-start: 0;
  margin-left: 0;
  padding-inline-start: 0;
`;

const Error = styled.li`
  color: ${props => props.theme.colors.red};
  font-size: ${props => props.theme.fontSizes.desktop.xs}px;
  font-weight: ${props => props.theme.fontWeights.normal};
  line-height: ${props => props.theme.lineHeights.desktop.xs}px;
  margin-top: 8px;
  transition: all 0.2s ease;
`;

const ErrorMessage = ({ children }) => (
  <Errors>
    <Error>{children}</Error>
  </Errors>
);

export default ErrorMessage;
