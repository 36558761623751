import React from "react";
import styled from "styled-components";
import { Field } from "react-final-form";

import InputCheckbox from "./InputCheckbox";
import InputWrapper from "./InputWrapper";

const Span = styled.span`
  a {
    color: ${props => props.theme.colors.navyHover};
  }
`;

const InputCheckboxStatement = ({
  disabled,
  id,
  label,
  name,
  validate,
}) => {
  return (
    <InputWrapper>
      <Field
        disabled={disabled}
        component={InputCheckbox}
        id={id}
        label={<Span>{label}</Span>}
        name={name}
        type="checkbox"
        validate={validate}
      />
    </InputWrapper>
  );
};

export default InputCheckboxStatement;
