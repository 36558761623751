import React from "react";
import styled from "styled-components";

import Label from "./Label";
import InputWrapper from "./InputWrapper";
import ErrorMessage from "./ErrorMessage";

const Flex = styled.div`
  align-items: center;
  display: flex;
`;

const Input = styled.input`
  display: none;
`;

const StyledLabel = styled(Label)`
  align-items: initial;
  color: ${props => props.theme.colors.primary};
  cursor: ${props => (props.disabled ? "text" : "pointer")};
  display: flex;
  font-size: ${props => props.theme.fontSizes.mobile.s}px;
  padding-left: 24px;
  position: relative;
  transition: all 0.2s ease;
  line-height: 16px;

  span {
    display: block;
  }

  &::before {
    background-color: ${props =>
      props.checked ? props.theme.colors.blue : props.theme.colors.white};
    border: 1px solid
      ${props =>
        props.checked ? props.theme.colors.grey03 : props.theme.colors.grey04};
    box-sizing: border-box;
    content: "";
    cursor: pointer;
    display: inline-block;
    flex-shrink: 0;
    flex-wrap: wrap;
    height: 16px;
    justify-content: center;
    left: 0;
    margin-right: 12px;
    position: absolute;
    transition: all 0.2s ease;
    width: 16px;
  }

  &::after {
    border-bottom: 2px solid;
    border-left: 2px solid;
    color: ${props => props.theme.colors.white};
    content: "";
    display: inline-block;
    height: 4px;
    left: 3px;
    opacity: 1;
    position: absolute;
    top: 4px;
    transform: rotate(-45deg);
    transition: all 0.2s ease;
    width: 8px;
  }
`;

const InputCheckbox = ({ disabled, input, id, name, label, meta }) => {
  const checked = input.checked || input.value;
  const hasError = (meta.error || meta.submitError) && meta.touched;

  return (
    <InputWrapper>
      <Flex>
        <Input
          id={id}
          name={name}
          {...input}
          type="checkbox"
          onChange={event => {
            input.onChange(event);
            input.onBlur(event);
          }}
        />
        <StyledLabel htmlFor={id} disabled={disabled} checked={checked}>
          {label}
        </StyledLabel>
      </Flex>
      {hasError && (
        <ErrorMessage>{meta.error || meta.submitError}</ErrorMessage>
      )}
    </InputWrapper>
  );
};

export default InputCheckbox;
