import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 24px;

  &:first-child {
    margin-top: 0;
  }
`;

const InputWrapper = ({ children, className }) => (
  <Wrapper className={className}>{children}</Wrapper>
);

InputWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default InputWrapper;
