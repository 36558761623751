import React, { useState } from "react";
import Recaptcha from "react-recaptcha";

import ErrorMessage from "./ErrorMessage";
import InputWrapper from "./InputWrapper";

const InputRecaptcha = ({ input, name, meta }) => {
  const [setRecaptchaToken] = useState(null);

  const verifyCallback = response => {
    input.onChange(response);
    setRecaptchaToken(response);
  };

  const callback = () => {};

  return (
    <InputWrapper>
      <Recaptcha
        sitekey="6LcoVuMUAAAAACvMdk7J6z-ppeQFrZKsorNh6wf6"
        type="invisible"
        size="normal"
        verifyCallback={verifyCallback}
        onloadCallback={callback}
        render="explicit"
      />

      <input name={name} type="hidden" {...input} />

      {(meta.error || meta.submitError) && meta.touched && (
        <ErrorMessage>{meta.error || meta.submitError}</ErrorMessage>
      )}
    </InputWrapper>
  );
};

export default InputRecaptcha;
