const EMAIL_FORMAT_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

const required = value => (value ? undefined : "This field is required");

const emailFormatCheck = value =>
  EMAIL_FORMAT_REGEX.test(value)
    ? undefined
    : "Enter a valid email address";

export { composeValidators, required, emailFormatCheck };
