import React, { useEffect, useState } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import styled from "styled-components";

import Loading from "../Loading";
import Spacer from "../Spacer";
import Button from "../Button";
import InputCheckboxStatement from "./InputCheckboxStatement";
import InputRecaptcha from "./InputRecaptcha";
import InputTextField from "./InputTextField";
import InputSelect from "./InputSelect";
import InputTextarea from "./InputTextarea";
import InputWrapper from "./InputWrapper";
import FormAlert from "./FormAlert";
import { required, emailFormatCheck, composeValidators } from "./validation";
import useDataLayer from "../../../context/DataLayer";
import { request, parseAPIErrors } from "./client";

const REASON_OPTIONS = [
  "I am interested in employment opportunities at an IPG DXTRA Health agency.",
  "I am a client interested in working with IPG DXTRA Health.",
  "I am interested in learning more about IPG DXTRA Health's solutions.",
];

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
`;

const ContactForm = () => {
  const dataLayer = useDataLayer();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [config, setConfig] = useState(null);

  useEffect(() => {
    if (config) {
      return;
    }

    const getConfig = async () => {
      try {
        const resp = await request("/config-web.json");
        setConfig(resp);
      } catch (err) {
        console.error("config", err);
      }
    };

    getConfig();
  }, [config]);

  const onSubmit = async (values, form) => {
    const { contactAPI } = config;
    const options = {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      await request(contactAPI, options);

      setSuccess(true);

      Object.keys(values).forEach((key) => {
        form.change(key, undefined);
        form.resetFieldState(key);
      });

      dataLayer.push({
        event: "formSubmission",
        submitSuccess: true,
      });
    } catch (error) {
      dataLayer.push({
        event: "formSubmission",
        submitSuccess: false,
      });

      return parseAPIErrors(error);
    }
  };

  if (!config) {
    return (
      <LoadingContainer>
        <Loading variant="dark" />
      </LoadingContainer>
    );
  }

  return (
    <>
      <FinalForm onSubmit={onSubmit}>
        {({ handleSubmit, submitting }) => (
          <Spacer
            padding={{
              xs: "60px 0 60px",
              m: "50px 0 100px",
              l: "70px 0 100px",
            }}
          >
            {success && <FormAlert />}
            <form onSubmit={handleSubmit}>
              <Field
                component={InputTextField}
                id="name"
                label="Full name"
                name="name"
                type="text"
                validate={required}
              />

              <Field
                component={InputTextField}
                id="email"
                label="Email address"
                name="email"
                type="email"
                validate={composeValidators(required, emailFormatCheck)}
              />

              <Field
                component={InputSelect}
                id="reason"
                label="Reason for contact"
                options={REASON_OPTIONS}
                name="reason"
                type="select"
              />

              <Field
                component={InputTextarea}
                name="message"
                label="Message"
                validate={required}
              />

              <InputCheckboxStatement
                id="marketing"
                name="marketing"
                label={
                  <>
                    I consent to receiving marketing communications from IPG
                    DXTRA Health by email
                  </>
                }
              />

              <InputCheckboxStatement
                id="terms"
                name="terms"
                label={
                  <>
                    By submitting this form I accept the{" "}
                    <a href="/terms-of-use" target="_blank">
                      terms & conditions
                    </a>
                  </>
                }
                validate={required}
              />

              <Field
                component={InputRecaptcha}
                name="recaptchaToken"
                validate={required}
              />

              <InputWrapper>
                <Button
                  variant="primary"
                  submit
                  loading={submitting}
                  disabled={submitting || success}
                  title="Submit"
                />
              </InputWrapper>
            </form>
          </Spacer>
        )}
      </FinalForm>
    </>
  );
};

export default ContactForm;
